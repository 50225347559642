<template>
  <div class="mb-7">
    <v-card-text class="pa-5 border-bottom">
      <h3
        class="subtitle blue-grey--text text--darken-2 font-weight-regular mt-3"
      >
        Informações dos serviços do contrato com o fornecedor
      </h3>
    </v-card-text>
    <v-card-text class="pa-5">
      <v-form @submit.prevent="saveServices">
        <div class="mt-4">

          <v-data-table
            disable-sort
            :headers="headers"
            :items="servicosAutorizados"
            sort-by="descricao"
            ref="table"
            class="border"
          >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-toolbar-title>Serviços registrados</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-icon medium @click="() => getServicosAutorizados()" title="Atualizar"
              >mdi-refresh</v-icon
            >
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        </template>
        <template v-slot:no-data>
          <v-btn color="primary" @click="getServicosAutorizados">Reset</v-btn>
        </template>
      </v-data-table>
           
          <div class="d-flex justify-end mt-4">
            <v-btn color="primary" dark type="submit">Salvar</v-btn>
          </div>
        </div>
      </v-form>
    </v-card-text>
    <v-dialog v-model="dialog" max-width="500px">
      <cadastro-servico
        formTitle="Cadastrar Etapa"
        ref="cadastroServico"
        v-model="servicoEdicaoEtapa"
        v-if="dialog"
        :value="this.servicoEdicaoEtapa"
        :terminalPublicID="this.terminalPublicID"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import CadastroServico from './etapaTerminal/CadastroServico.vue'
import PatioApi from '@/services/patio/index'

export default {
  props: {
    terminalPublicID: String,
  },
  components: {
    CadastroServico,
  },
  data() {
    return {
      selected: [],
      value: "" ,
      isAllSelected: false,
      dialog: false,
      servicosAutorizados: [],
      headers: [
        { text: 'Descrição', value: 'servico.descricao' },
        { text: 'Ações', value: 'actions', sortable: false },
      ],
      servicoEdicaoEtapa: {
        id: null,
        descricao: null,
        prestador: null,
        foto: null,
        tipoTarifa: null,
        etapas: [],
      },
    }
  },
  computed: {
    ...mapState('auth', ['empresaAtual']),
    ...mapState('patio', ['servicos']),
  },
  methods: {
    ...mapActions('template', ['successMessage', 'errorMessage']),
    ...mapActions('patio', ['getServicos']),

    editItem(item) {
      this.servicoEdicaoEtapa = { ...item }
      this.dialog = true
    },

    async getServicosAutorizados() {
      if (!this.empresaAtual.public_id) return
      
      const servicosAutorizados = await PatioApi.getServicosAutorizadosFornecedorParams({
        fornecedor : this.empresaAtual.public_id,
      })

      this.servicosAutorizados = servicosAutorizados
    },
  },

  async created() {
    await this.getServicosAutorizados()
  },
}
</script>

<style lang="scss">
.tbody {
  border-bottom-color: rgb(128, 128, 128);
  border-collapse: separate;
  border-left-color: rgb(128, 128, 128);
  border-right-color: rgb(128, 128, 128);
  border-top-color: rgb(128, 128, 128);
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: table-row-group;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  height: 144px;
  line-height: 24px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 0px;
  margin-top: 0px;
  overflow-wrap: break-word;
  padding-bottom: 0px;
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  tab-size: 4;
  text-decoration-thickness: auto;
  text-indent: 0px;
  text-rendering: optimizelegibility;
  text-size-adjust: 100%;
  vertical-align: middle;
  white-space: normal;
  word-break: normal;

  tr {
    border-bottom-color: rgb(128, 128, 128);
    border-collapse: separate;
    border-left-color: rgb(128, 128, 128);
    border-right-color: rgb(128, 128, 128);
    border-top-color: rgb(128, 128, 128);
    box-sizing: border-box;
    color: rgba(0, 0, 0, 0.87);
    display: table-row;
    font-family: Poppins, sans-serif;
    font-size: 16px;
    height: 48px;
    line-height: 24px;
    margin-bottom: 0px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    overflow-wrap: break-word;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    tab-size: 4;
    text-decoration-thickness: auto;
    text-indent: 0px;
    text-rendering: optimizelegibility;
    text-size-adjust: 100%;
    vertical-align: middle;
    white-space: normal;
    word-break: normal;

    .valor {
      width: 10%;
    }

    td {
      border-bottom-color: rgba(0, 0, 0, 0.12);
      border-bottom-style: solid;
      border-bottom-width: 1px;
      border-collapse: separate;
      box-sizing: border-box;
      color: rgba(0, 0, 0, 0.87);
      display: table-cell;
      font-family: Poppins, sans-serif;
      font-size: 14px;
      height: 48px;
      line-height: 24px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      overflow-wrap: break-word;
      padding-bottom: 0px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 0px;
      tab-size: 4;
      text-align: start;
      text-decoration-thickness: auto;
      text-indent: 0px;
      text-rendering: optimizelegibility;
      text-size-adjust: 100%;
      transition-delay: 0s;
      transition-duration: 0.2s;
      transition-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
      vertical-align: middle;
      white-space: normal;
      word-break: normal;
    }
  }
}
</style>