<template>
  <v-container fluid>
    <BaseBreadcrumb
      :title="page.title"
      :icon="page.icon"
      :breadcrumbs="breadcrumbs"
    ></BaseBreadcrumb>
    <v-row>
      <v-col cols="12" sm="12">
        <BaseCard heading="Terminal">
          <form-adicionar-contrato
            v-if="mostrarFormAdicionar || irParaCadastro"
            :perfilContrato="perfilContrato"
            @adicionado="mostrarFormAdicionar = false"
          >
            <template #append>
              <tab-content
                title="Armazens"
                icon="ti-shopping-cart-full"
                :before-change="() => true"
              >
                <list-armazem />
              </tab-content>
            </template>
          </form-adicionar-contrato>
          <form-editar-contrato
            v-else-if="editarContrato"
            v-model="contratoSelecionado"
          >
            <template #append-tab>
              <v-tab href="#armazens">
                Armazens <v-icon>mdi-cart</v-icon></v-tab
              >
              <v-tab href="#servicos">
                Serviços <v-icon>mdi-file-table-box-multiple</v-icon></v-tab
              >
            </template>
            <template #tab-item>
              <v-tab-item value="armazens" eager>
                <list-armazem class="pa-4" :contratoPublicID="contratoSelecionado.public_id"/>
              </v-tab-item>
              <v-tab-item value="servicos" eager>
                <dados-servico class="pa-4" :terminalPublicID="contratoSelecionado.public_id"/>
              </v-tab-item>
            </template>
          </form-editar-contrato>
          <listar-contratos
            v-else
            v-model="terminais"
            :tela="permission"
            title="Terminais registrados"
            titleNew="Novo Terminal"
            :perfilContrato="perfilContrato"
            @mostrarFormAdicionar="mostrarFormAdicionar = true"
            @editar="editar"
            @update="handleGetContratos"
            @updateInativos="handleGetContratosInativos"
          />
        </BaseCard>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TabContent } from 'vue-form-wizard'

import { mapActions, mapGetters, mapState } from 'vuex'
import ListarContratos from '@/components/contrato/ListarContratos'
import FormAdicionarContrato from '@/components/contrato/adicionar/FormAdicionarContrato.vue'
import FormEditarContrato from '@/components/contrato/editar/FormEditarContrato.vue'
import ListArmazem from '@/components/contrato/terminal/ListArmazem.vue'
import DadosServico from '@/components/contrato/terminal/DadosServico.vue'
import Perfil from '@/utils/perfil'
import { BasicPermissions, Sequences } from "@/utils/permissions"

export default {
  props: {
    irParaCadastro: {
      default: false,
    },
  },
  data() {
    return {
      Sequences,
      mostrarFormAdicionar: false,
      editarContrato: false,
      page: {
        title: 'Gerenciamento de terminais',
      },
      breadcrumbs: [
        {
          text: 'Geral',
          disabled: false,
        },
        {
          text: 'Terminal',
          disabled: true,
        },
      ],
      perfilContrato: Perfil.Terminal,
      contratoSelecionado: {},
    }
  },
  components: {
    ListarContratos,
    FormAdicionarContrato,
    FormEditarContrato,
    TabContent,
    ListArmazem,
    DadosServico,
  },
  computed: {
    ...mapGetters('contratos', ['terminais']),
    ...mapState('auth', ['empresaAtual']),
    permission() {
      return Sequences.Terminais.toString()
    },
    canList() {
      return this.$canDo(BasicPermissions.VISUALIZAR, this.permission)
    },
    canCreate() {
      return this.$canDo(BasicPermissions.CADASTRAR, this.permission)
    },
    canUpdate() {
      return this.$canDo(BasicPermissions.EDITAR, this.permission)
    },
    canDelete() {
      return this.$canDo(BasicPermissions.EXCLUIR, this.permission)
    },
  },
  async created() {
    if (this.canList) {
      await this.handleGetContratos()
    }
  },
  methods: {
    ...mapActions('contratos', ['getContratos', 'getContratosInativos']),
    ...mapActions('template', ['errorMessage']),

    async handleGetContratos() {
      await this.handleGet(this.getContratos)
    },

    async handleGetContratosInativos() {
      await this.handleGet(this.getContratosInativos)
    },

    async handleGet(callback) {
      const params = { perfil: this.perfilContrato }
      try {
        await callback({ params })
      } catch (e) {
        this.errorMessage('Erro ao carregar lista de empresas contratadas')
      }
    },

    editar(contrato) {
      this.contratoSelecionado = contrato
      this.editarContrato = true
    },
  },
}
</script>

<style></style>
