<template>
  <v-card class="mt-4 pa-2 text-start">
    <v-card-title>{{ armazem.descricao }}</v-card-title>
    <v-card-subtitle class="d-flex align-center">
      <v-icon class="mr-1" small>mdi-weight-pound</v-icon>
      <span> {{ armazem.capacidade }} (TON) </span>
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col
          cols="3"
          v-for="subProduto in armazem.sub_produtos"
          :key="subProduto.id"
        >
          {{ subProduto.descricao }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn color="primary" @click="$emit('edit', armazem)">Editar</v-btn>
      <v-btn color="error" @click="$emit('delete', armazem)">Remover</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    armazem: {
      type: Object,
      required: true,
    },
  },
}
</script>
