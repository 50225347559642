<template>
  <div>
    <confirm-dialog @confirm="confirmDelete" />

    <v-dialog v-model="adding" max-width="25%">
      <add-armazem v-if="adding" @add="save" :armazem="itemToEdit" />
    </v-dialog>

    <div class="text-end px-2 py-4">
      <v-btn color="primary" @click="addItem"> Adicionar </v-btn>
    </div>
    <v-row>
      <v-col cols="4" v-for="armazem in armazens" :key="armazem.public_id">
        <list-armazem-card
          :armazem="armazem"
          @delete="deleteItem"
          @edit="editItem"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/template/ConfirmDialog'

import AddArmazem from './AddArmazem.vue'
import ListArmazemCard from './ListArmazemCard.vue'
import { mapActions, mapMutations, mapState } from 'vuex'

export default {
  props: {
    contratoPublicID: String,
  },
  components: { ListArmazemCard, AddArmazem, ConfirmDialog },
  data() {
    return {
      adding: false,
      itemToDelete: {},
      itemToEdit: {},
    }
  },
  computed: {
    ...mapState('contratos', ['armazens']),
  },
  methods: {
    ...mapActions('template', [
      'successMessage',
      'errorMessage',
      'setDialogMessage',
    ]),
    ...mapActions('contratos', [
      'getArmazens',
      'addArmazens',
      'editArmazem',
      'deleteArmazem',
    ]),
    ...mapMutations('contratos', [
      'ADD_ARMAZEM',
      'DELETE_ARMAZEM',
      'EDIT_ARMAZEM',
    ]),

    save(armazem) {
      if (this.contratoPublicID) {
        this.saveApi(armazem)
      } else {
        this.saveState(armazem)
      }
    },
    saveApi(armazem) {
      if (armazem.public_id) {
        this.editArmazem({
          contratoPublicID: this.contratoPublicID,
          armazem: {
            ...armazem,
            sub_produtos: armazem.sub_produtos.map((s) => s.id),
          },
        })
      } else {
        this.addArmazens({
          contratoPublicID: this.contratoPublicID,
          armazens: [
            {
              ...armazem,
              terminal: this.contratoPublicID,
              sub_produtos: armazem.sub_produtos.map((s) => s.id),
            },
          ],
        })
      }
      this.adding = false
    },
    saveState(armazem) {
      if (armazem.public_id >= 0) {
        this.EDIT_ARMAZEM(armazem)
      } else {
        const public_id = this.armazens.length
        this.ADD_ARMAZEM({ ...armazem, public_id })
      }
      this.adding = false
    },
    addItem() {
      this.adding = true
      this.itemToEdit = {}
      this.itemToDelete = {}
    },
    editItem(armazem) {
      this.itemToEdit = armazem
      this.itemToDelete = {}
      this.adding = true
    },
    deleteItem(armazem) {
      this.itemToEdit = {}
      this.itemToDelete = armazem
      this.setDialogMessage({
        title: 'Confirmar exclusão?',
        message: 'Deseja mesmo excluir esse armazem?',
      })
    },
    confirmDelete() {
      if (this.contratoPublicID) {
        this.deleteArmazem({
          contratoPublicID: this.contratoPublicID,
          armazemPublicID: this.itemToDelete.public_id,
        })
      } else {
        this.DELETE_ARMAZEM(this.itemToDelete)
      }
      this.successMessage('Armazem excluído com sucesso!')
    },
  },
  async created() {
    if (this.contratoPublicID) {
      try {
        await this.getArmazens({ contratoPublicID: this.contratoPublicID })
      } catch (e) {
        this.errorMessage(e.response.data)
      }
    }
  },
}
</script>
