<template>
  <v-card class="pa-2">
    <v-card-title> {{ isEdit ? 'Editar' : 'Adicionar' }} Armazem </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete
            v-if="empresaAtual.gate"
            label="Fornecedor"
            v-model="fornecedor"
            item-text="business_name"
            item-value="public_id"
            return-object
            :items="fornecedores"
            :loading="loading"
          />
          <v-autocomplete
            v-else
            label="Gate"
            v-model="gate"
            item-text="business_name"
            item-value="public_id"
            return-object
            :items="gates"
            :loading="loading"
            @change="handleGetFornecedor"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field label="Descrição" v-model="forms.descricao" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Capacidade (TON)"
            v-model="forms.capacidade"
            v-mask="['#.##', '##.##', '###.##']"
          />
        </v-col>
      </v-row>
      <v-row v-if="fornecedor.produtos.length" class="text-start">
        <v-col>
          <div class="text-center">
            <span>SubProdutos</span>
          </div>
          <v-treeview
            v-model="forms.sub_produtos"
            selectable
            open-on-click
            transition
            dense
            item-text="descricao"
            item-key="id"
            item-children="subprodutos"
            return-object
            :items="fornecedor.produtos"
          />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="d-flex justify-end">
      <v-btn color="primary" @click="$emit('add', { ...forms, fornecedor })">
        {{ isEdit ? 'Salvar' : 'Adicionar' }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import ContratoAPI from '@/services/tenants/contratos'

export default {
  props: {
    armazem: {
      type: Object,
      default: () => this.getDefaultForms(),
    },
  },
  data() {
    return {
      loading: false,
      gate: {},
      gates: [],
      fornecedor: {
        produtos: [],
      },
      forms: this.getDefaultForms(),
    }
  },
  computed: {
    ...mapState('fornecedores', ['fornecedores']),
    ...mapState('auth', ['empresaAtual']),
    isEdit() {
      return this.armazem && this.armazem.public_id !== undefined
    },
  },
  methods: {
    ...mapActions('fornecedores', [
      'getFornecedores',
      'getFornecedorByPublicID',
      'getFornecedoresByParams',
    ]),
    getDefaultForms() {
      return { descricao: '', capacidade: '', sub_produtos: [] }
    },
    async handleGetFornecedor(gate) {
      const data = await this.getFornecedoresByParams({
        params: {
          fornecedor: this.empresaAtual.public_id,
        },
        empresa: gate,
      })
      this.fornecedor = data[0]
    },
  },
  async created() {
    if (this.empresaAtual.gate) {
      if (this.isEdit) {
        Object.assign(this.forms, {
          ...this.armazem,
          capacidade: Number(this.armazem.capacidade).toFixed(2),
        })
        
        if (
          typeof this.armazem.public_id === 'string' &&
          this.armazem.sub_produtos.length
        ) {
          this.fornecedor = await this.getFornecedorByPublicID({
            publicID: this.armazem.sub_produtos[0].fornecedor,
          })
        } else {
          this.fornecedor = this.armazem.fornecedor
        }
      } else {
        this.forms = this.getDefaultForms()
        this.fornecedor = {
          produtos: [],
        }
      }
      this.loading = true
      await this.getFornecedores()
      this.loading = false
    } else {
      const { possible_gates } = await ContratoAPI.getPossiveisGates(
        this.empresaAtual.public_id
      )
      this.gates = possible_gates
      if (this.isEdit) {
        Object.assign(this.forms, {
          ...this.armazem,
          capacidade: Number(this.armazem.capacidade).toFixed(2),
        })
      }
    }
  },
}
</script>
